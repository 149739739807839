import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

// Store
import { useSelector } from 'react-redux'

// Utils
import { COLUMN_NAMES } from '../utils/excelHandler'

// Components
import PdfComponent from './PdfComponent'
import AgChart from './AgChart'

// Utils
import { registerFonts } from '../utils/registerPdfFonts'

function Table() {
  const rows = useSelector((state) => state.data.rows)

  const [chartmg, setChartImg] = useState(null)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    const modal = document.getElementById('my_modal_2')

    registerFonts()

    const handleModalClose = () => {
      console.log('Modal closed')
      // Perform other actions here

      setChartImg(null)
      setSelected(null)
    }

    // Attach the 'close' event listener
    modal.addEventListener('close', handleModalClose)

    // Cleanup the event listener on component unmount
    return () => {
      modal.removeEventListener('close', handleModalClose)
    }
  }, [])

  return (
    <div>
      <div className="my-5 flex justify-center">
        <div className="card card-compact bg-base-100 shadow-xl basis-[800px] mx-10 my-5 ">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>نام و نام خانوادگی</th>
                <th>جنسیت</th>
                <th>وضعیت تاهل</th>
                <th>سن</th>
                <th>عملیات</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => {
                return (
                  <tr key={i}>
                    <th>{i}</th>
                    <th>{row[COLUMN_NAMES.NAME]}</th>
                    <td>{row[COLUMN_NAMES.GENDER]}</td>
                    <td>{row[COLUMN_NAMES.MARRIED]}</td>
                    <td>{row[COLUMN_NAMES.AGE]}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setSelected(i)
                          document.getElementById('my_modal_2').showModal()
                        }}
                      >
                        گزارش
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <AgChart
          setChartImg={setChartImg}
          row={rows[selected]}
        />

        <dialog
          id="my_modal_2"
          className="modal"
        >
          <div className="modal-box max-w-[900px]">
            {/* Render if any row is selected */}

            {/* {selected !== null && ( */}
            {selected !== null && chartmg && (
              <PDFViewer style={{ width: '100%', height: '800px' }}>
                <PdfComponent
                  chartImg={chartmg}
                  data={rows[selected]}
                />
              </PDFViewer>

              // <PdfComponent
              //   chartImg={chartmg}
              //   data={rows[selected]}
              // />
            )}
          </div>
          <form
            method="dialog"
            className="modal-backdrop"
          >
            <button>close</button>
          </form>
        </dialog>
      </div>
    </div>
  )
}

export default Table
