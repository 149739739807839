import { Font } from '@react-pdf/renderer'

export const registerFonts = () => {
  // Font.registerHyphenationCallback((word) => [word])

  // Font.register({
  //   family: 'yekan',
  //   src: `${process.env.PUBLIC_URL}/assets/fonts/yekan-font/Yekan.ttf`,
  // })

  // Font.register({
  //   family: 'bzar',
  //   src: `${process.env.PUBLIC_URL}/assets/fonts/bzar-font/BZARBOLD.TTF`,
  // })

  // Font.register({
  //   family: 'mitra',
  //   src: `${process.env.PUBLIC_URL}/assets/fonts/mitra-font/Far_Mitra.ttf`,
  // })

  Font.register({
    family: 'sahel',
    src: `${process.env.PUBLIC_URL}/assets/fonts/sahel-font/Sahel.ttf`,
    // src: `${process.env.PUBLIC_URL}/assets/fonts/sahel-font/Sahel-Black.ttf`,
  })

  // Font.register({
  //   family: 'zain',
  //   src: `${process.env.PUBLIC_URL}/assets/fonts/Zain-Bold.ttf`,
  // })

  // Font.register({
  //   family: 'notoSansArabic',
  //   // src: `${process.env.PUBLIC_URL}/assets/fonts/noto-sans-arabic/NotoSansArabic-Black.ttf`,
  //   src: `${process.env.PUBLIC_URL}/assets/fonts/noto-sans-arabic/NotoSansArabic_Condensed-Light.ttf`,
  // })

  // Font.register({
  //   family: 'Noto Sans',
  //   src: `http://fonts.gstatic.com/s/notosans/v6/LeFlHvsZjXu2c3ZRgBq9nKCWcynf_cDxXwCLxiixG1c.ttf`,
  // })

  // Font.register({
  //   family: 'Rubik',
  //   src: `http://fonts.gstatic.com/s/rubik/v4/2AfMVb-218AAzRWsLqegwg.ttf`,
  // })

  // @font-face {
  //   font-family: 'Rubik';
  //   font-style: normal;
  //   font-weight: 400;
  //   src: local('Rubik'), local('Rubik-Regular'), url(http://fonts.gstatic.com/s/rubik/v4/2AfMVb-218AAzRWsLqegwg.ttf) format('truetype');
  // }

  // @font-face {
  //   font-family: 'Noto Sans';
  //   font-style: normal;
  //   font-weight: 400;
  //   src: local('Noto Sans'), local('NotoSans'), url(http://fonts.gstatic.com/s/notosans/v6/LeFlHvsZjXu2c3ZRgBq9nKCWcynf_cDxXwCLxiixG1c.ttf) format('truetype');
  // }
}
