export const MAX_FLOAT = 2

export const TRANSLATIONS = {
  // FIELDS
  Negative_affect: 'عاطفه پذیری منفی',
  Detachment: 'انزواطلبی',
  Antagonism: 'ستیزه جویی',
  Disinhibition: 'عدم بازداری',
  Psychoticism: 'روان پریش خویی',

  // ADJECTIVES
  Emotional_lability: 'ناپایداری هیجانی',
  Anxiousness: 'اضطراب',
  Restricted_affectivity: 'عاطفه پذیری محدود',
  Separation_insecurity: 'ناایمنی جدایی',
  Hostility: 'خصومت',
  Perseveration: 'درجاماندگی',
  Submissiveness: 'سلطه پذیری',
  Withdrawal: 'کناره گیری',
  Anhedonia: 'بی لذتی',
  Depressivity: 'افسرده خویی',
  Intimacy_avoidance: 'صمیمیت گریزی',
  Suspiciousness: 'شکاکیت',
  Manipulativeness: 'دستکاری/ بازی دادن دیگران',
  Deceitfulness: 'حیله گری',
  Grandiosity: 'خودبزرگ بینی',
  Attention_seeking: 'توجه طلبی',
  Callousness: 'بی عاطفگی',
  Irresponsibility: 'بی مسئولیتی',
  Impulsivity: 'تکانش گری',
  Risk_taking: 'ریسک پذیری',
  Rigid_perfectionism: 'کمال گرایی نامنعطف',

  Distractibility: 'حواس پرتی',
  Unusual_beliefs: 'باور های غیرعادی',
  Eccentricity: 'نامتعارف بودن',
  Perceptual_dysregulation: 'بی نظمی شناختی و ادراکی',
}

export const PDF_TEXT_STYLES = {
  position: 'absolute',
  // Works
  // fontFamily: 'yekan',
  // fontSize: 13,

  // Works
  fontFamily: 'sahel',

  // No ID Issue
  // fontFamily: 'Helvetica',
  // fontFamily: 'Noto Sans',
  // fontFamily: 'Rubik',

  // ID Issue
  // fontFamily: 'notoSansArabic',


  // fontFamily: 'zain',

  // NOT Work
  // fontFamily: 'bzar',
  // fontFamily: 'mitra',

  fontSize: 11,
}
