import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    rows: [],
  },
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    setRows: (state, action) => {
      state.rows = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  // increment,
  setRows,
} = dataSlice.actions

export default dataSlice.reducer
