// Styles
import './App.sass'

// Components
import Upload from './pages/Upload'
import Table from './components/Table'

function App() {
  return (
    <div
      dir="rtl" // RTL Daisy Ui Component
      className="App"
    >
      {/* <Counter /> */}
      <Upload />

      {/* <div style={{ fontFamily: 'yekan' }}>سلام</div>
      <div style={{ fontFamily: 'BZAR' }}>سلام</div> */}

      <Table />
    </div>
  )
}

export default App
