export function convertToArabicIndicNumerals(number) {
  const arabicIndicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  return number
      .toString()
      .split('')
      .map(digit => (/\d/.test(digit) ? arabicIndicDigits[digit] : digit))
      .join('');
}

// Does not fix font issue
// export function convertToArabicIndicNumerals(number) {
//   return number
// }