import React, { useState, useRef, useEffect, useMemo } from 'react'
import { AgCharts } from 'ag-charts-react'

// Consts
import { TRANSLATIONS } from '../utils/consts'

const AgChart = ({ setChartImg, row }) => {
  const chartRef = useRef(null)
  const [data, setData] = useState([])

  useEffect(() => {
    const newData = []

    // console.log(`row`)
    // console.log(row)

    if (row?.mainData) {
      Object.entries(TRANSLATIONS).forEach(([key, value], index) => {
        // Exception | Add Empty Space to separate fields and adjectives
        if (index === 5)
          newData.push({
            xKey: '',
            services: 0,
          })

        newData.push({
          xKey: value,
          services: row.mainData[value],
        })
      })

      // console.log(`newData`)
      // console.log(newData)

      setTimeout(() => {
        chartRef.current
          .getImageDataURL({ width: 1300, height: 864 })
          .then((imageDataURL) => {
            setChartImg(imageDataURL)
          })
      }, 100)
    }

    setData(newData)
  }, [row])

  const options = useMemo(() => {
    return {
      // title: {
      //   text: "Apple's Revenue by Product Category",
      // },
      // subtitle: {
      //   text: 'In Billion U.S. Dollars',
      // },
      theme: {
        overrides: {
          common: {
            // Chart Title
            title: {
              fontFamily: 'yekan',
              fontSize: 15,
            },
            // yKey style
            axes: {
              category: {
                label: {
                  fontFamily: 'yekan',
                  fontSize: 10,
                },
              },
            },
            // yName style
            legend: {
              item: {
                label: {
                  fontFamily: 'yekan',
                },
              },
            },
            series: {
              label: {
                fontFamily: 'yekan',
              },
              // calloutLabelKey
              calloutLabel: {
                fontFamily: 'yekan',
              },
              // sectorLabel
              sectorLabel: {
                fontFamily: 'yekan',
              },
            },
            overlays: {
              noData: {
                renderer: () => '<h5 class="overlay-text">دیتا وجود ندارد</h5>',
              },
            },
          },
        },
      },

      data,
      series: [
        // {
        //   type: 'bar',
        //   direction: 'horizontal',
        //   xKey: 'quarter',
        //   yKey: 'iphone',
        //   yName: 'iPhone',
        // },
        {
          type: 'bar',
          direction: 'horizontal',
          xKey: 'xKey',
          yKey: 'services',
          yName: 'Services',
        },
      ],
    }
  }, [data])

  // const download = () => {
  //   chartRef.current.download()
  // }

  // const downloadFixedSize = () => {
  //   chartRef.current.download({ width: 500, height: 600 })
  // }

  // const getImageDataURL = () => {
  //   chartRef.current.getImageDataURL()
  // }

  // const openImage = () => {
  //   chartRef.current
  //     .getImageDataURL({ width: 1300, height: 864 })
  //     .then((imageDataURL) => {
  //       setChartImg(imageDataURL)

  //       const image = new Image()
  //       image.src = imageDataURL
  //       const tab = window.open(imageDataURL)
  //       if (tab) {
  //         tab.document.write(image.outerHTML)
  //         tab.document.close()
  //       }
  //     })
  // }

  // const handleProcess = () => {
  //   openImage()
  // }

  return (
    <>
      {/* <button
        onClick={handleProcess}
        className="inline-block cursor-pointer rounded-md bg-gray-800 px-4 py-3"
      /> */}

      <AgCharts
        style={{
          display: 'none',
          // width: '1300px',
          // height: '864px',
        }}
        ref={chartRef}
        options={options}
      />
    </>
  )
}

export default AgChart
