import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  BlobProvider,
  PDFDownloadLink,
} from '@react-pdf/renderer'
import { COLUMN_NAMES } from '../utils/excelHandler'
import { PDF_TEXT_STYLES, TRANSLATIONS } from '../utils/consts'
import { ScoreBoardItems } from './ScoreBoardItems'
import { convertToArabicIndicNumerals } from '../utils/helpler'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
  },
})

const pageAttrs = {
  // size=:"A4",
  size: [595.28], // Only Define With, Height will be auto
  style: styles.page,
}

const imageSrc = (number) =>
  `${process.env.PUBLIC_URL}/assets/images/${number}.jpg`

const LEFT_SPACES = [40, 270, 500]

const TOP_SPACES = [90, 115]

// Create Document Component
const PDFComponent = ({ chartImg, data }) => {
  return (
    <Document>
      {/* PAGE 1 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          {/* <Text>Section #1</Text> */}

          {/* <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ718nztPNJfCbDJjZG8fOkejBnBAeQw5eAUA&s" /> */}
          <Image src={imageSrc(1)} />
        </View>
      </Page>

      {/* PAGE 2 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(2)} />
        </View>
      </Page>

      {/* PAGE 3 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(3)} />
          <Text
            style={{
              top: TOP_SPACES[0],
              left: LEFT_SPACES[0],
              ...PDF_TEXT_STYLES,
            }}
          >
            {data[COLUMN_NAMES.EDUCATION]}
          </Text>
          <Text
            style={{
              top: TOP_SPACES[1],
              left: LEFT_SPACES[0],
              ...PDF_TEXT_STYLES,
            }}
          >
            {data[COLUMN_NAMES.UNDER_TREATMENT]}
          </Text>
          <Text
            style={{
              top: TOP_SPACES[0],
              left: LEFT_SPACES[1],
              ...PDF_TEXT_STYLES,
            }}
          >
            {data[COLUMN_NAMES.AGE]}
          </Text>
          <Text
            style={{
              top: TOP_SPACES[1],
              left: LEFT_SPACES[1],
              ...PDF_TEXT_STYLES,
            }}
          >
            {data[COLUMN_NAMES.MARRIED]}
          </Text>
          <Text
            style={{
              top: TOP_SPACES[0],
              left: LEFT_SPACES[2],
              ...PDF_TEXT_STYLES,
            }}
          >
            {data[COLUMN_NAMES.SHENASE_PASOKH_DAHANDE]}
          </Text>
          <Text
            style={{
              top: TOP_SPACES[1],
              left: LEFT_SPACES[2],
              ...PDF_TEXT_STYLES,
            }}
          >
            {data[COLUMN_NAMES.GENDER]}
          </Text>

          {/* شاخص های اعتبار */}
          <Text
            style={{
              top: 280,
              left: 370,
              ...PDF_TEXT_STYLES,
            }}
          >
            {`${convertToArabicIndicNumerals(data.ANSWERING_TIME)} دقیقه`}
          </Text>
          <Text
            style={{
              top: 276,
              left: 82,
              ...PDF_TEXT_STYLES,
            }}
          >
            {convertToArabicIndicNumerals(data.AVERAGE_QUESTION_TIME)}
          </Text>
          {/* VRIN SCORE AND RESULT */}
          <Text
            style={{
              top: 325,
              left: 380,
              ...PDF_TEXT_STYLES,
            }}
          >
            {convertToArabicIndicNumerals(data.VRIN)}
          </Text>
          <Text
            style={{
              top: 325,
              left: 65,
              ...PDF_TEXT_STYLES,
            }}
          >
            {data.VRIN >= 17 ? 'وجود (احتمالی)' : 'عدم'} {'ناهمسانی'}
          </Text>
          {/* ORS SCORE AND RESULT */}
          <Text
            style={{
              top: 350,
              left: 380,
              ...PDF_TEXT_STYLES,
            }}
          >
            {convertToArabicIndicNumerals(data.ORS)}
          </Text>
          <Text
            style={{
              top: 350,
              left: 65,
              ...PDF_TEXT_STYLES,
            }}
          >
            {data.ORS >= 3 ? 'وجود (احتمالی)' : 'عدم'} {'بیش گزارش دهی'}
          </Text>
          {/* PRD SCORE AND RESULT */}
          <Text
            style={{
              top: 380,
              left: 380,
              ...PDF_TEXT_STYLES,
            }}
          >
            {convertToArabicIndicNumerals(data.PRD)}
          </Text>
          <Text
            style={{
              top: 380,
              left: 65,
              ...PDF_TEXT_STYLES,
            }}
          >
            {data.PRD < 11 && 'کم گزارش دهی'}
            {data.PRD >= 11 && data.PRD < 20 && 'دامنه مرزی'}
            {data.PRD >= 20 && 'پاسخ های معتبر'}
          </Text>
        </View>
      </Page>

      {/* PAGE 4 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(4)} />
        </View>
      </Page>

      {/* PAGE 5 | Chart Page*/}
      <Page
        {...pageAttrs}
        size={[700]}
      >
        <View style={styles.section}>
          {chartImg && (
            <Image
              style={{ position: 'fixed' }}
              src={chartImg}
            />
          )}

          {/* <Image
            src={imageSrc(5)}
          /> */}

          <Image src={imageSrc(5)} />
        </View>
      </Page>

      {/* PAGE 6 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(6)} />

          <ScoreBoardItems
            page={6}
            data={data}
          />
        </View>
      </Page>

      {/* PAGE 7 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(7)} />

          <ScoreBoardItems
            page={7}
            data={data}
          />
        </View>
      </Page>

      {/* PAGE 8 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(8)} />
        </View>
      </Page>

      {/* PAGE 9 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(9)} />
        </View>
      </Page>

      {/* PAGE 10 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(10)} />
        </View>
      </Page>

      {/* PAGE 11 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(11)} />
        </View>
      </Page>

      {/* PAGE 12 */}
      <Page {...pageAttrs}>
        <View style={styles.section}>
          <Image src={imageSrc(12)} />
        </View>
      </Page>
    </Document>
  )
}

export default PDFComponent

// export default ({ chartImg, data }) => {
//   return (
//     // <PDFDownloadLink
//     //   document={
//     //     <PDFComponent
//     //       chartImg={chartImg}
//     //       data={data}
//     //     />
//     //   }
//     //   fileName="somename.pdf"
//     // >
//     //   {({ blob, url, loading, error }) =>
//     //     loading ? `Loading document...` : `Download now!`
//     //   }
//     // </PDFDownloadLink>

//     <BlobProvider document={
//            <PDFComponent
//           chartImg={chartImg}
//           data={data}
//         />
//     }>
//       {({ blob, url, loading, error }) => {
//         // Do whatever you need with blob here
//         return <div>There's something going on on the fly</div>
//       }}
//     </BlobProvider>
//   )
// }
