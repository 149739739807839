import counterReducer from './counterSlice'
import dataReducer from './dataSlice'

import { configureStore } from '@reduxjs/toolkit'

export default configureStore({
  reducer: {
    counter: counterReducer,
    data: dataReducer,
  },
})
