import React, { Fragment } from 'react'
import { Text } from '@react-pdf/renderer'
import { PDF_TEXT_STYLES, TRANSLATIONS } from '../utils/consts'
import { convertToArabicIndicNumerals } from '../utils/helpler'

export function ScoreBoardItems({ data, page }) {
  const SCORE_SCHEDULE_LEFT_SPACES = [110, 320]

  // Top Space By Page Number
  const FIRST_ITEM_TOP = page === 6 ? 134 : 77

  const SPACE_BETWEEN_ITEMS = 21

  const array = Object.entries(TRANSLATIONS)

  let items = []

  items = array.filter(([key, value], index) => {
    if (page === 6) {
      if (index >= array.length - 4) return false
      return true
    } else if (page === 7) {
      if (index >= array.length - 4) return true
      return false
    }
  })

  return (
    <>
      {items.map(([key, value], index) => {
        // For Each in Excel Columns
        // console.log(`key`)
        // console.log(key)
        // console.log(`value`)
        // console.log(value)

        let top = FIRST_ITEM_TOP + SPACE_BETWEEN_ITEMS * index

        // Skip a space for adjectives
        if (index >= 5) top = top + 16

        // if (index >= array.length - 4) return ''

        let result

        // Exception for Psychoticism
        if (value === TRANSLATIONS.Psychoticism)
          result = data.mainData[value] > 1 ? 'بالینی' : 'غیربالینی'
        else {
          result = data.mainData[value] > 1.5 ? 'بالینی' : 'غیربالینی'
        }

        return (
          <Fragment key={key}>
            <Text
              style={{
                top,
                left: SCORE_SCHEDULE_LEFT_SPACES[1],
                ...PDF_TEXT_STYLES,
              }}
            >
              {convertToArabicIndicNumerals(data.mainData[value])}
            </Text>
            <Text
              style={{
                top,
                left: SCORE_SCHEDULE_LEFT_SPACES[0],
                ...PDF_TEXT_STYLES,
              }}
            >
              {result}
            </Text>
          </Fragment>
        )
      })}
    </>
  )
}
