import React, { useEffect, useState } from 'react'

// Components

// Redix
import { useDispatch } from 'react-redux'
import { setRows } from '../store/dataSlice'

// Parsers
import ExcelJS from 'exceljs'
import { excelToJson, excelToCalculate } from '../utils/excelHandler'

function Upload() {
  const [data, setData] = useState([])

  const dispatch = useDispatch()

  const handleProcess = () => {}

  const handleFileUpload = async (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = async (e) => {
      const arrayBuffer = e.target.result
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(arrayBuffer)

      const worksheet = workbook.worksheets[0]
      const jsonData = []

      worksheet.eachRow((row, rowNumber) => {
        jsonData.push(row.values)
      })

      console.log(`jsonData`)
      console.log(jsonData)

      setData(jsonData)

      // excelToJson(jsonData)
      const data = excelToCalculate(jsonData)

      dispatch(setRows(data))
    }

    reader.readAsArrayBuffer(file)
  }

  return (
    <div>
      <div className="">
        <div className="card card-compact bg-base-100 w-96 shadow-xl mx-auto my-5">
          <div className="card-body">
            <div className="">فایل اکسل را وارد کنید</div>
            <input
              type="file"
              accept=".xlsx"
              className="file-input w-full max-w-xs"
              onChange={handleFileUpload}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Upload
