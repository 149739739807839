import { MAX_FLOAT, TRANSLATIONS } from './consts'

export const COLUMN_NAMES = {
  PASOKH_NAME: 'PASOKH_NAME',
  SHENASE_PASOKH_DAHANDE: 'SHENASE_PASOKH_DAHANDE',
  NAME: 'NAME',
  CURER_NAME: 'CURER_NAME',
  GENDER: 'GENDER',
  MARRIED: 'MARRIED',
  AGE: 'AGE',
  EDUCATION: 'EDUCATION',
  UNDER_TREATMENT: 'UNDER_TREATMENT',
  START_TIME: 'START_TIME',
  END_TIME: 'END_TIME',
}

const FIRST_COLUMNS = {
  [COLUMN_NAMES.PASOKH_NAME]: 1,
  [COLUMN_NAMES.SHENASE_PASOKH_DAHANDE]: 2,
  [COLUMN_NAMES.NAME]: 3,
  [COLUMN_NAMES.CURER_NAME]: 4,
  [COLUMN_NAMES.GENDER]: 5,
  [COLUMN_NAMES.MARRIED]: 6,
  [COLUMN_NAMES.AGE]: 7,
  [COLUMN_NAMES.EDUCATION]: 8,
  [COLUMN_NAMES.UNDER_TREATMENT]: 9,
}

const SPEC_COLUMNS = {
  ...FIRST_COLUMNS,
  [COLUMN_NAMES.START_TIME]: 272,
  [COLUMN_NAMES.END_TIME]: 273,
}

const ALL_QUESTIONS_COUNT = 263

const SKIP_COUNT = Object.keys(FIRST_COLUMNS).length // Static Column Count

const colDefs = {
  answerLetter: {
    title: 'پاسخنامه',
  },
  responserId: {
    title: 'شناسه پاسخ دهنده',
  },
  name: {
    title: 'نام و نام خانواد',
  },
  coachName: {
    title: 'نام و نام خانوادگی روان‌درمانگر',
  },
  gender: {
    title: 'جنسیت',
  },
  married: {
    title: 'وضعیت تأهل',
  },
  age: {
    title: 'سن',
  },
  graduate: {
    title: 'آخرین مدرک تحصیلی',
  },
  drugUsage: {
    title:
      'آیا در حال حاضر به روان‌پزشک مراجعه می‌کنید و دارویی که ایشان تجویز کرده‌اند را مصرف',
  },
}

/**
 * Sets the index property for each column definition in the `colDefs` object based on their position in the `referenceCols` array.
 * @param {Array<string>} referenceCols - An array of strings representing the reference columns. Each string can contain multiple column titles.
 */
function setIndexForColumnDefs(refrenceCols) {
  // For Each in Pre Defined Excel Columns
  Object.entries(colDefs).forEach(([key, value]) => {
    // For Each in Excel Columns
    refrenceCols.forEach((col, index) => {
      if (col.includes(value.title)) return (value.index = index)
    })
  })
}

/**
 * @param {number} targetIndex - The index of the column to find the key for.
 * @returns {string|undefined} - The key corresponding to the column with the given index, or `undefined` if no match is found.
 */
function findColumnKeyByIndex(targetIndex) {
  for (const [key, value] of Object.entries(colDefs)) {
    if (value.index === targetIndex) {
      return key
    }
  }
  return undefined
}

// export const excelToJson = (ExcelJson) => {
//   // const rowsCount = ExcelJson.length - 3

//   const modifiedData = []

//   const excelCols = ExcelJson[0]

//   setIndexForColumnDefs(excelCols)

//   console.log(`colDefs`)
//   console.log(colDefs)

//   const rowData = {}

//   // For Each in Excel Rows
//   // First 3 rows are columns
//   for (let i = 3; i < ExcelJson.length; i++) {
//     ExcelJson[i].forEach((string, index) => {
//       const colDef = findColumnKeyByIndex(index)

//       if (colDef) {
//         rowData[colDef] = string
//         return
//       }
//     })

//     console.log(`rowData`)
//     console.log(rowData)
//   }

//   return rowData
// }

export const excelToCalculate = (ExcelJson) => {
  const rowData = []

  // For Each in Excel Rows
  // First 3 rows are columns
  for (let i = 3; i < ExcelJson.length; i++) {
    const PIDs = ExcelJson[i]

    rowData.push(getResultofRow(PIDs))
  }

  return rowData
}

/**
 * Adds a constant skip count to each index in the given array.
 * @param {number[]} indexes - An array of numerical indexes.
 * @returns {number[]} - A new array with the skip count added to each index.
 */
function addSkipCountToIndexes(indexes) {
  for (let i = 0; i < indexes.length; i++) indexes[i] = indexes[i] + SKIP_COUNT

  return indexes
}

// const average = (array) => array.reduce((a, b) => a + b) / array.length

const average = (array) => {
  const avg = array.reduce((a, b) => a + b) / array.length
  return parseFloat(avg.toFixed(MAX_FLOAT))
}

const sum = (array) => array.reduce((partialSum, a) => partialSum + a, 0)

/**
 * @param {number} value
 * @returns {number}
 */
function reverseValue(value) {
  switch (value) {
    case 0:
      return 3
    case 1:
      return 2
    case 2:
      return 1
    case 3:
      return 0
    default:
      return value
  }
}

/**
 * @param {number} value
 * @returns {number}
 */
function orsValue(value) {
  switch (value) {
    case 3:
      return 1
    case 2:
      return 0
    case 1:
      return 0
    case 0:
      return 0
    default:
      return value
  }
}

/**
 * @param {number[]} array
 * @param {number[]} indexes
 * @returns {number} - The average of the values at the specified indexes in the array.
 */
function getAverageOfIndexesFromArray(array, indexes) {
  const values = []

  indexes.forEach((index, i) => {
    values[i] = array[index]
  })

  const averageResult = average(values)

  // Get a number with max float
  return parseFloat(averageResult.toFixed(MAX_FLOAT))
}

/**
 * @param {number[]} array
 * @param {number[]} indexes
 * @returns {number} - The sum of the values at the specified indexes in the array.
 */
function getSumOfIndexesFromArray(array, indexes) {
  const values = []

  indexes.forEach((index, i) => {
    values[i] = array[index]
  })

  return sum(values)
}

function getAbsoluteOfMi(PIDs, indexes) {
  let sum = 0

  for (let i = 0; i < indexes.length; i++) {
    if (i % 2 !== 0) continue

    const index = indexes[i]
    const nextIndex = indexes[i + 1]

    sum = sum + Math.abs(PIDs[index] - PIDs[nextIndex])
  }
  return sum
}

/**
 * @param {object} PIDs
 * @returns {object} - Calculated Result
 */
function getResultofRow(PIDs) {
  const result = {
    mainData: [],
  }

  // Add Extra Columns to result
  Object.entries(FIRST_COLUMNS).forEach(([key, value]) => {
    result[key] = PIDs[value]
  })

  // Two Last Columns Are Start And End Time
  result.START_TIME = PIDs[PIDs.length - 2] + ''
  result.END_TIME = PIDs[PIDs.length - 1] + ''

  // Calculate ANSWERING_TIME
  const diff = Math.abs(new Date(result.END_TIME) - new Date(result.START_TIME))
  const minutes = Math.floor(diff / 1000 / 60)

  result.ANSWERING_TIME = minutes
  result.AVERAGE_QUESTION_TIME = ((minutes * 60) / ALL_QUESTIONS_COUNT).toFixed(
    1
  )

  // Execute 1
  const indexes = addSkipCountToIndexes([
    7, 30, 35, 58, 87, 90, 96, 97, 98, 131, 142, 155, 164, 177, 210, 215,
  ])

  indexes.forEach((index, i) => {
    PIDs[index] = reverseValue(PIDs[index])
  })

  // My Custom Step
  // Because excel rows data array start from index 1, i just added an undefined index to match PIDs Count
  const REVERSED_PIDS = [undefined]
  const ORS_PIDS = [undefined]

  PIDs.forEach((pid, i) => {
    ORS_PIDS.push(orsValue(pid))
    REVERSED_PIDS.push(reverseValue(pid))
  })

  // ******************* ADJECTIVES *******************

  // Anhedonia
  result.mainData[TRANSLATIONS.Anhedonia] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([1, 23, 26, 30, 124, 155, 157, 189])
  )

  // Anxiousness
  result.mainData[TRANSLATIONS.Anxiousness] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([79, 93, 95, 96, 109, 110, 130, 141, 174])
  )

  // Attention_seeking
  result.mainData[TRANSLATIONS.Attention_seeking] =
    getAverageOfIndexesFromArray(
      PIDs,
      addSkipCountToIndexes([14, 43, 74, 111, 113, 173, 191, 211])
    )

  // Callousness
  result.mainData[TRANSLATIONS.Callousness] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([
      11, 13, 19, 54, 72, 73, 90, 153, 166, 183, 198, 200, 207, 208,
    ])
  )

  // Deceitfulness
  result.mainData[TRANSLATIONS.Deceitfulness] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([41, 53, 56, 76, 126, 134, 142, 206, 214, 218])
  )

  // Depressivity
  result.mainData[TRANSLATIONS.Depressivity] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([
      27, 61, 66, 81, 86, 104, 119, 148, 151, 163, 168, 169, 178, 212,
    ])
  )

  // Distractibility
  result.mainData[TRANSLATIONS.Distractibility] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([6, 29, 47, 68, 88, 118, 132, 144, 199])
  )

  // Eccentricity
  result.mainData[TRANSLATIONS.Eccentricity] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([
      5, 21, 24, 25, 33, 52, 55, 70, 71, 152, 172, 185, 205,
    ])
  )

  // Emotional_lability
  result.mainData[TRANSLATIONS.Emotional_lability] =
    getAverageOfIndexesFromArray(
      PIDs,
      addSkipCountToIndexes([18, 62, 102, 122, 138, 165, 181])
    )

  // Grandiosity
  result.mainData[TRANSLATIONS.Grandiosity] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([40, 65, 114, 179, 187, 197])
  )

  // Hostility
  result.mainData[TRANSLATIONS.Hostility] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([28, 32, 38, 85, 92, 116, 158, 170, 188, 216])
  )

  // Impulsivity
  result.mainData[TRANSLATIONS.Impulsivity] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([4, 16, 17, 22, 58, 204])
  )

  // Intimacy_avoidance
  result.mainData[TRANSLATIONS.Intimacy_avoidance] =
    getAverageOfIndexesFromArray(
      PIDs,
      addSkipCountToIndexes([89, 97, 108, 120, 145, 203])
    )

  // Irresponsibility
  result.mainData[TRANSLATIONS.Irresponsibility] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([31, 129, 156, 160, 171, 201, 210])
  )

  // Manipulativeness
  result.mainData[TRANSLATIONS.Manipulativeness] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([107, 125, 162, 180, 219])
  )

  // Perceptual_dysregulation
  result.mainData[TRANSLATIONS.Perceptual_dysregulation] =
    getAverageOfIndexesFromArray(
      PIDs,
      addSkipCountToIndexes([
        36, 37, 42, 44, 59, 77, 83, 154, 192, 193, 213, 217,
      ])
    )

  // Perseveration
  result.mainData[TRANSLATIONS.Perseveration] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([46, 51, 60, 78, 80, 100, 121, 128, 137])
  )

  // Restricted_affectivity
  result.mainData[TRANSLATIONS.Restricted_affectivity] =
    getAverageOfIndexesFromArray(
      PIDs,
      addSkipCountToIndexes([8, 45, 84, 91, 101, 167, 184])
    )

  // Restricted_affectivity_R
  result.mainData.Restricted_affectivity_R = getAverageOfIndexesFromArray(
    REVERSED_PIDS,
    addSkipCountToIndexes([8, 45, 84, 91, 101, 167, 184])
  )

  // Rigid_perfectionism
  result.mainData[TRANSLATIONS.Rigid_perfectionism] =
    getAverageOfIndexesFromArray(
      PIDs,
      addSkipCountToIndexes([34, 49, 105, 115, 123, 135, 140, 176, 196, 220])
    )

  // Rigid_perfectionism_R
  result.mainData.Rigid_perfectionism_R = getAverageOfIndexesFromArray(
    REVERSED_PIDS,
    addSkipCountToIndexes([34, 49, 105, 115, 123, 135, 140, 176, 196, 220])
  )

  // Risk_taking
  result.mainData[TRANSLATIONS.Risk_taking] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([
      3, 7, 35, 39, 48, 67, 69, 87, 98, 112, 159, 164, 195, 215,
    ])
  )

  // Separation_insecurity
  result.mainData[TRANSLATIONS.Separation_insecurity] =
    getAverageOfIndexesFromArray(
      PIDs,
      addSkipCountToIndexes([12, 50, 57, 64, 127, 149, 175])
    )

  // Submissiveness
  result.mainData[TRANSLATIONS.Submissiveness] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([9, 15, 63, 202])
  )

  // Suspiciousness
  result.mainData[TRANSLATIONS.Suspiciousness] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([2, 103, 117, 131, 133, 177, 190])
  )

  // Unusual_beliefs
  result.mainData[TRANSLATIONS.Unusual_beliefs] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([94, 99, 106, 139, 143, 150, 194, 209])
  )

  // Withdrawal
  result.mainData[TRANSLATIONS.Withdrawal] = getAverageOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([10, 20, 75, 82, 136, 146, 147, 161, 182, 186])
  )

  // ***********************************

  // Negative_affect
  result.mainData[TRANSLATIONS.Negative_affect] = average([
    result.mainData[TRANSLATIONS.Emotional_lability],
    result.mainData[TRANSLATIONS.Anxiousness],
    result.mainData.Restricted_affectivity_R,
    result.mainData[TRANSLATIONS.Separation_insecurity],
    result.mainData[TRANSLATIONS.Hostility],
    result.mainData[TRANSLATIONS.Perseveration],
    result.mainData[TRANSLATIONS.Submissiveness],
  ])

  // Detachment
  result.mainData[TRANSLATIONS.Detachment] = average([
    result.mainData[TRANSLATIONS.Withdrawal],
    result.mainData[TRANSLATIONS.Anhedonia],
    result.mainData[TRANSLATIONS.Depressivity],
    result.mainData[TRANSLATIONS.Intimacy_avoidance],
    result.mainData[TRANSLATIONS.Suspiciousness],
  ])

  // Antagonism
  result.mainData[TRANSLATIONS.Antagonism] = average([
    result.mainData[TRANSLATIONS.Manipulativeness],
    result.mainData[TRANSLATIONS.Deceitfulness],
    result.mainData[TRANSLATIONS.Grandiosity],
    result.mainData[TRANSLATIONS.Attention_seeking],
    result.mainData[TRANSLATIONS.Callousness],
  ])

  // Disinhibition
  result.mainData[TRANSLATIONS.Disinhibition] = average([
    result.mainData[TRANSLATIONS.Irresponsibility],
    result.mainData[TRANSLATIONS.Impulsivity],
    result.mainData[TRANSLATIONS.Distractibility],
    result.mainData[TRANSLATIONS.Risk_taking],
    result.mainData.Rigid_perfectionism_R,
  ])

  // Psychoticism
  result.mainData[TRANSLATIONS.Psychoticism] = average([
    result.mainData[TRANSLATIONS.Unusual_beliefs],
    result.mainData[TRANSLATIONS.Eccentricity],
    result.mainData[TRANSLATIONS.Perceptual_dysregulation],
  ])

  // ****************** FIELDS ******************

  // VRIN
  result.VRIN = getAbsoluteOfMi(
    PIDs,
    addSkipCountToIndexes([
      79, 174, 109, 110, 102, 122, 138, 181, 38, 92, 80, 128, 50, 127, 148, 169,
      89, 145, 74, 173, 191, 211, 153, 166, 125, 180, 105, 123, 132, 144, 21,
      55, 24, 25, 52, 152, 70, 71, 172, 185,
    ])
  )

  // ORS
  result.ORS = getSumOfIndexesFromArray(
    ORS_PIDS,
    addSkipCountToIndexes([170, 8, 178, 2, 166, 40, 39, 171, 44, 150])
  )

  // PRD
  result.PRD = getSumOfIndexesFromArray(
    PIDs,
    addSkipCountToIndexes([
      96, 122, 38, 119, 148, 163, 168, 169, 2, 136, 11, 183, 198, 162, 47, 199,
      98, 36, 42, 154, 192, 106,
    ])
  )

  // console.log(`result`)
  // console.log(result)

  return result
}
